/* eslint-disable */
import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p className="text-red-600 font-semibold">CONVOCATÒRIA URGENT!!!! 🚨</p>

                <p>
                    <strong>
                        Abans de tot cal recordar que més del 83% dels aspirants aptes en l'anterior
                        convocatòria de la policia local de Castelldefels 2024 van ser alumnes
                        nostres, inclosos el núm. 1 i 2.
                    </strong>
                </p>

                <p>
                    Enguany et portem un curs encara més renovat i complet, amb noves fitxes de
                    treball, material actualitzat i molt més!
                </p>

                <h3>
                    <strong>Contingut del curs:</strong>
                </h3>
                <ul>
                    <li>
                        Els 40 temes de temari, amb explicacions detallades, esquemes per memoritzar
                        i test per posar-vos a prova.
                    </li>
                    <li>Consistori i idees força</li>
                    <li>El municipi en xifres, barris, districtes, monuments... 🏙️</li>
                    <li>Premis 🏆</li>
                    <li>Esports ⚽</li>
                    <li>Personalitats 👤</li>
                    <li>Cultura general bàsica 📚</li>
                    <li>Vídeos de cultura general avançada 🎥</li>
                    <li>Actualitat setmanal i test per posar-se a prova 📰</li>
                    <li>9 exàmens oficials dels últims anys 📝</li>
                    <li>Simulacres mensuals de cultura i temari 📅</li>
                </ul>

                <p>
                    A més, per ser alumne, us donarem accés a un grup exclusiu de WhatsApp amb
                    Kahoot per assolir un millor aprenentatge. 💬
                </p>

                <p>
                    <strong>
                        Com veus, a iOpos Acadèmia tenim la millor preparació, i per això els
                        nostres alumnes aconsegueixen accedir en temps rècord a les convocatòries de
                        policia! 🚔
                    </strong>
                </p>

                <p>
                    <strong>Per sols 89,99 €</strong> amb possibilitat de pagar en 3 cops* i sense
                    matrícula. <br />
                    El curs estarà obert fins al dia del primer examen.
                </p>

                <p>
                    <strong>T'esperem al curs!</strong> 🎓
                </p>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/VfSUnxMQAUk"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Apunta't al nostre curs preparatori i uneix-te al 83% d'aspirants que han aconseguit l'aprovat a l'examen de la Policia Local de Castelldefels. Amb material actualitzat, simulacres mensuals i accés exclusiu a un grup de WhatsApp, t'oferim la millor preparació per superar l'examen en temps rècord."
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;

/*import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                
                <Title>Curs Online per la Policia Local de Castelldefels 2025</Title>
                <Text>
                    Si quieres acceder a la Policía local de Castelldefels 2025 te presentamos el
                    mejor curso online
                    <br />
                    <br />
                    Antes de nada, decirte que en la anterior convocatoria prácticamente todos los
                    aspirantes que fueron aptos eran alumnos nuestros incluido el número 1.
                    <br />
                    <br />
                    iOpos Academia vuelve este año con un campus online aún más completo.
                    <br />
                    <br />
                    Un campus que se adapta a ti y a tus horarios.
                    <br />
                    <br />
                    El único campus que desde que te matriculas tienes acceso a todos los contenidos
                    sin límite y donde se irá añadiendo nuevo contenido.
                    <br />
                    <br />
                    Dentro del campus encontrarás.
                    <br />
                    <br />
                    -Todas las noticias de actualidad semanales y test para ponerse a prueba.
                    <br />
                    <br />
                    -Información sobre el consistorio, distritos, la ciudad y curiosidades.
                    <br />
                    <br />
                    -Cultura general básica, avanzada y videos quiz por temáticas
                    <br />
                    <br />
                    -Los 40 temas del temario exigidos en las bases, ideas fuerza de cada tema y
                    test para ponerse a prueba.
                    <br />
                    <br />
                    -7 exámenes oficiales de las últimas convocatorias de Castelldefels.
                    <br />
                    <br />
                    -La prueba práctica y explicaciones.
                    <br />
                    <br />
                    -Todos los premios, deportes y personalidades
                    <br />
                    <br />
                    Y simulacros ómnibus para comprobar tu nivel
                    <br />
                    <br />
                    ¿Ahora entiendes por qué nuestros alumnos obtienen las mejores notas año tras
                    año?
                    <br />
                    <br />
                    Y si te ha parecido poco… por ser alumno tendrás acceso a un grupo de whatsapp
                    donde semanalmente tendrás kahoots y retos de nivel.
                    <br />
                    <br />
                    El curso estará disponible hasta el día del examen.
                    <br />
                    <br />
                    Por solo 79.99 de un solo pago.
                    <br />
                    <br />
                    Accede ya al curso y fórmate con garantías
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/KHolnbmCzZk"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </ProductTemplate>
    );
};

export default Product;*/
